import { useEffect, useState } from 'react';
import { getSessionStorage, removeItemFromSessionStorage } from '../util/sessionStorage';
import { onSubscribeSubscription } from '../util/subscription';
import { useDispatch, useSelector } from 'react-redux';
import { initializeCardPaymentData } from '../ducks/stripe.duck';
import { useConfiguration } from '../context/configurationContext';
import { useRouteConfiguration } from '../context/routeConfigurationContext';
import { useHistory } from 'react-router-dom';

const useContactModal = ({ from, onSaveCurrentState, onClose, onRender }) => {
  const dispatch = useDispatch();
  const config = useConfiguration();
  const history = useHistory();
  const routes = useRouteConfiguration();
  const { currentUser } = useSelector(state => state.user);
  const onInitializeCardPaymentData = () => dispatch(initializeCardPaymentData());
  const callSetInitialValues = (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage));

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  useEffect(() => {
    const storedData = getSessionStorage(config.subscription.sessionStorageKey);
    if (storedData?.from !== from) return;
    onRender?.();
    // If sessionStore contains saved data, open the modal
    setIsContactModalOpen(!!storedData.openContactModal);
    removeItemFromSessionStorage(config.subscription.sessionStorageKey);
  }, []);
  const onSubscribe = () => {
    onSaveCurrentState();
    onSubscribeSubscription({
      history,
      currentUser,
      callSetInitialValues,
      onInitializeCardPaymentData,
      routes,
    })({
      subscriptionPackId: config.subscription.defaultSubscriptionPackId,
    });
  };
  const onContactModalClose = () => {
    setIsContactModalOpen(false);
    return onClose?.();
  };

  return {
    onSubscribe,
    onContactModalClose,
    isContactModalOpen,
    setIsContactModalOpen,
  };
};

export default useContactModal;
