import Decimal from 'decimal.js';
import { isAfterDate, subtractTime } from './dates';
import { types as sdkTypes } from './sdkLoader';

export const setSessionStorage = (data, storageKey) => {
  if (typeof window === 'undefined') return;
  if (!data) return;
  const storedData = {
    ...data,
    storedAt: new Date(),
  };
  const replacer = function(k, v) {
    if (this[k] instanceof Date) {
      return { date: v, _serializedType: 'SerializableDate' };
    }
    if (this[k] instanceof Decimal) {
      return { decimal: v, _serializedType: 'SerializableDecimal' };
    }
    if (this[k] instanceof sdkTypes.UUID) {
      return { uuid: v.uuid, _serializedType: 'SerializableUUID' };
    }
    if (this[k] instanceof sdkTypes.Money) {
      return { amount: v.amount, currency: v.currency, _serializedType: 'SerializableMoney' };
    }
    if (this[k] instanceof sdkTypes.LatLngBounds) {
      return {
        ne: { lat: v.ne.lat, lng: v.ne.lng },
        sw: { lat: v.sw.lat, lng: v.sw.lng },
        _serializedType: 'SerializableLatLngBounds',
      };
    }
    return sdkTypes.replacer(k, v);
  };

  const storableData = JSON.stringify(storedData, replacer);
  window.sessionStorage.setItem(storageKey, storableData);
};

export const getSessionStorage = storageKey => {
  if (typeof window === 'undefined') return {};
  const storedData = window.sessionStorage.getItem(storageKey);
  const reviver = function(k, v) {
    if (v && typeof v === 'object') {
      switch (v._serializedType) {
        case 'SerializableDate':
          return new Date(v.date);
        case 'SerializableDecimal':
          return new Decimal(v.decimal);
        case 'SerializableUUID':
          return new sdkTypes.UUID(v.uuid);
        case 'SerializableMoney':
          return new sdkTypes.Money(v.amount, v.currency);
        case 'SerializableLatLngBounds':
          return new sdkTypes.LatLngBounds(
            new sdkTypes.LatLng(v.ne.lat, v.ne.lng),
            new sdkTypes.LatLng(v.sw.lat, v.sw.lng)
          );
        default:
          break;
      }
    }
    return sdkTypes.reviver(k, v);
  };
  const { storedAt, ...data } = storedData ? JSON.parse(storedData, reviver) : {};
  // If sessionStore contains freshly saved data (max 1 day old), use it
  const isFreshlySaved = storedAt
    ? isAfterDate(storedAt, subtractTime(new Date(), 1, 'days'))
    : false;
  if (isFreshlySaved) return data;
};

export const removeItemFromSessionStorage = storageKey => {
  if (typeof window === 'undefined') return;
  window.sessionStorage.removeItem(storageKey);
};
